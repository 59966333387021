<template>
  <base-users>
    <template v-slot:default>
      <v-card class="card-user mx-0 my-4 d-inline-block pb-0">
        <div class="card-user_block d-inline-flex flex-wrap position-relative">
          <div class="card-user_avatar" v-if="getProfileImgUrl">
            <img :src="getProfileImgUrl" alt="Profile Image">
          </div>
          <div class="card-user_avatar" v-else>
            <v-icon class="card-user_avatar_icon" color="#fff" large>mdi-account-circle</v-icon>
          </div>
          <div class="card-user_content">
            <h3 class="card-user_name mb-5">{{ staff.name }}
              <!-- <span class="fs-14 font-weight-regular pl-3" v-if="staff.crmRep == true">{{ $t("users.crmRep") }}</span> -->
              </h3>
            <!-- <p class="fs-14 font-weight-medium">{{ staff.name }}</p> -->
            <p class="card-user_txt mb-0">{{ $t("users.email") }}</p>
            <p class="fs-14 font-weight-bold mb-0">{{ staff.email }}</p>
            <!-- <p class="card-user_txt mb-0 pt-2">{{ $t("users.lastLogin") }}</p>
            <p class="fs-14 font-weight-bold mb-0">{{ staff.lastLoginAt }}</p> -->
          </div>
        </div>
      </v-card>
    </template>
  </base-users>
</template>

<script>
import BaseUsers from '../../../../components/Users/BaseUsers'
import { STAFF } from '@/api/graphql/staff/staff-info'
import { mapGetters, mapActions } from 'vuex'
import gql from 'graphql-tag'

export default {
  name: 'UserInformation',
  data () {
    return {
      staff: {
        name: null,
        email: null
      },
      profileImgUrl: null
    }
  },
  mounted () {
    this.getUserInformation()
    // get Avatar
    this.actionGetProfileImgUrl()
  },
  // watch: {
  //   getProfileImgUrl () {
  //     console.log('getProfileImgUrl', this.getProfileImgUrl)
  //   }
  // },
  computed: {
    ...mapGetters(['getProfileImgUrl'])
  },
  components: {
    BaseUsers
  },
  methods: {
    ...mapActions(['actionGetProfileImgUrl']),
    getUserInformation () {
      this.$apollo.query({
        query: gql`${STAFF}`,
        variables: {
          id: parseInt(localStorage.getItem('id') || 1)
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.staff = data.data.staff
      }).catch((error) => {
        console.error(error)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.card-user_avatar {
  background: none;
  border-radius: 50% !important;
  height: 126px;
  width: 126px;
  img {
    width: 126px;
    height: 126px;
    margin-right: 30px;
    border-radius: 50% !important;
  }
}
.card-user_block {
  border: 0;
}
.card-user_content {
  color: #000;
}
.card-user_txt {
  font-size: 10px;
}
</style>
